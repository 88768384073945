<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-29 09:20:06
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\garden.vue
-->
<template>
  <div class="container">
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <div class="year-total">
            <div class="list">
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/chanliang.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.totalAmount | numFilter(10000)
                  }}</span>
                  <span class="unit">万元</span>
                  <div class="name">销售总额</div>
                </div>
              </div>
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/xiahsoue.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.distributedAmountMap &&
                    sales.distributedAmountMap.distribute | numFilter(10000)
                  }}</span>
                  <span class="unit">万元</span>
                  <div class="name">已分配</div>
                </div>
              </div>
            </div>
          </div>
          <title2 name="种植情况"></title2>
          <div class="div-plantnow">
            <el-carousel :interval="5000" indicator-position="none" height="">
              <el-carousel-item
                v-for="(item, index) in plantCurrent"
                :key="index"
              >
                <div
                  class="div-plantnow-item"
                  :class="'lightLevel' + item.lightLevel"
                >
                  <div class="text-line">
                    <div class="text-cur">
                      <div class="label">当前种植：</div>
                      <div class="text1">{{ item.plantVariety }}</div>
                    </div>
                    <div class="text-cur">
                      <div class="label">
                        {{ item.startDate }}至{{ item.endDate }}
                      </div>
                    </div>
                  </div>
                  <div class="text-line">
                    <div class="text-cur">
                      <div class="label">预计产量：</div>
                      <div class="text1">
                        {{ item.estimateOutput || "-" }} 斤
                      </div>
                    </div>
                    <div class="text-cur">
                      <div class="label">预售数量：</div>
                      <div class="text2">{{ item.preSale || "-" }} 斤</div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="div-planthis">
            <div class="title3">
              <img
                class="title-img"
                src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/serve.png"
              />
              历史种植
            </div>
            <div class="scrollbar div-planthis-list">
              <div
                class="div-planthis-item"
                v-for="(item, index) in plantHistory"
                :key="index"
              >
                <div class="cycle">
                  {{ item.startDate + "至" + item.endDate + "：" }}
                  <div class="cycle-vari">{{ item.plantVariety }}</div>
                </div>
                <div class="text-line">
                  <div class="text-cur">
                    <div class="label">销量：</div>
                    <div class="text1">{{ item.sold }} 斤</div>
                  </div>
                  <div class="text-cur">
                    <div class="label">销售总额：</div>
                    <div class="text2">{{ item.soldAmount }}元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <title2 name="收入分配"></title2>
          <ul class="income-list">
            <li>
              <div>
                <span>农户</span>
                <span>收入总额(元)</span>
              </div>
              <div>
                <span>农户</span>
                <span>收入总额(元)</span>
              </div>
            </li>
          </ul>
          <ul class="income-list income-list-body">
            <vue-seamless-scroll
              :data="incomeRecords"
              :class-option="defaultOption"
            >
              <li v-for="item in incomeRecords" :key="item.id">
                <div @click="openIncome(item)">
                  <span>{{ item.householdName }}</span>
                  <span>{{ item.totalIncome }}</span>
                </div>
                <div
                  @click="openIncome(item.incomeRecords)"
                  v-if="item.incomeRecords"
                >
                  <span>{{ item.incomeRecords.householdName }}</span>
                  <span>{{ item.incomeRecords.totalIncome }}</span>
                </div>
              </li>
            </vue-seamless-scroll>
          </ul>
        </div>
        <div class="middle">
          <div class="div-static">
            <div class="top">{{ gardenInfo.gardenName }}</div>
            <div class="middle">
              <img
                src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/didian.png"
              />
              <span class="text">{{ gardenInfo.town }}</span>
            </div>
            <div class="bottom">
              <div class="bottom-item">
                <span class="label">面积（亩）</span>
                <span class="value">{{ gardenInfo.area | numFilter }}</span>
              </div>
              <div class="bottom-item">
                <span class="label">实时结对户数（户）</span>
                <span class="value">{{ gardenInfo.pairedHouseNum }}</span>
              </div>
              <div class="bottom-item">
                <span class="label">实时结对人数（人）</span>
                <span class="value">{{ gardenInfo.pairedPeopleNum }}</span>
              </div>
            </div>
          </div>
          <div class="div-pic">
            <el-carousel trigger="click" height="100%">
              <el-carousel-item
                v-for="(item, index) in gardenPhoto"
                :key="index"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.fileUrl"
                  :fit="imgfit"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="right">
          <template v-if="video">
            <title2 name="视频监控"></title2>
            <div class="video">
              <div class="current">
                <video
                  alt="暂无监控"
                  ref="videoRefsmall"
                  controls
                  autoplay
                ></video>
              </div>
              <div
                class="sider"
                v-if="gardenInfo.video && gardenInfo.video.length > 1"
              >
                <div
                  class="item"
                  :class="{ active: index === current }"
                  v-for="(item, index) in gardenInfo.video"
                  :key="item.id"
                  @click="changeVideo(index)"
                >
                  <span class="round"></span>{{ "视频" + (index + 1) }}
                </div>
              </div>
            </div>
          </template>
          <title2 name="志愿服务"></title2>
          <div class="div-service scrollbar" :class="{ 'add-height': !video }">
            <div
              class="div-service-item"
              v-for="(item, index) in serviceList"
              :key="index"
            >
              <el-image
                :src="item.url + '?x-oss-process=image/resize,h_188,w_140'"
                :fit="imgfit"
              ></el-image>
              <div class="item-right">
                <div class="unit">{{ item.serviceTypeLabel }}</div>
                <template
                  v-if="
                    item.serviceType === 'TECH_GUIDE_SERVICE' ||
                    item.serviceType === 'MNG_VOLUNTEER_SERVICE'
                  "
                >
                  <div class="datetime">
                    服务内容：{{ item.serviceContent }}
                  </div>
                  <div class="datetime">服务单位：{{ item.serviceUnit }}</div>
                  <div class="datetime">时长：{{ item.serviceHours }}小时</div>
                  <div class="datetime">投入：{{ item.serviceInput }}元</div>
                </template>
                <template v-if="item.serviceType === 'TO_COUNTRYSIDE'">
                  <div class="datetime">农资：{{ item.nzxx.nzName }}</div>
                  <div class="datetime">服务单位：{{ item.serviceUnit }}</div>
                  <div class="datetime">
                    农资总额：{{ item.nzxx.nzAmount }}元
                  </div>
                </template>
                <template v-if="item.serviceType === 'SUBSCRIPTION_BY_LOVE'">
                  <div class="datetime">
                    认购品种：<template v-if="item.list">{{
                      item.list.map((item1) => item1.variety).join("，")
                    }}</template>
                  </div>
                  <div class="datetime">认购单位：{{ item.serviceUnit }}</div>
                  <div class="datetime">
                    总量：<template v-if="item.list">{{
                      item.list.reduce(
                        (pre, cur) => pre + Number(cur.subVolume),
                        0
                      )
                    }}</template
                    >斤
                  </div>
                  <div class="datetime">
                    金额：<template v-if="item.list">{{
                      item.list.reduce(
                        (pre, cur) => pre + Number(cur.subAmount),
                        0
                      )
                    }}</template
                    >元
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="open" append-to-body>
      <title2 :name="name + '收入详情'">
        <div class="dialog-income-total">合计：{{ total }}元</div>
      </title2>
      <ul class="dialog-income-list">
        <li class="header">
          <span>序号</span>
          <span>收入类型</span>
          <span>收入(元)</span>
          <span>占比</span>
        </li>
        <li v-for="(item, index) in tableData" :key="index">
          <span>{{ index + 1 }}</span>
          <span>{{ item.incomeTypeLabel }}</span>
          <span>{{ item.incomeAmount }}</span>
          <span>{{ item.width }}</span>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import Hls from "hls.js";
export default {
  name: "garden",
  data() {
    return {
      // dialog
      open: false,
      total: "",
      name: "",
      tableData: [],
      // data
      hls: null,
      current: 0,
      imgfit: "cover",
      sales: {},
      plantCurrent: [],
      plantHistory: [],
      incomeRecords: [],
      gardenInfo: {},
      gardenPhoto: [],
      video: false,
      serviceList: [],
    };
  },
  computed: {
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    getResult() {
      this.$api.bigdata
        .getGardenDetail({
          id: this.$route.params.id,
        })
        .then((res) => {
          let result = res.data;
          if (!result) return;
          let gardenInfo = result.gardenInfo;
          let incomeRecords = result.incomeRecords;
          let newIncomeRecords = [];
          let newIncomeRecordsIndex = 0;
          incomeRecords.forEach((item, index) => {
            if (index === 0) {
              newIncomeRecords.push(item);
            } else {
              if (newIncomeRecords[newIncomeRecordsIndex].incomeRecords) {
                newIncomeRecordsIndex++;
                newIncomeRecords.push(item);
              } else {
                newIncomeRecords[newIncomeRecordsIndex].incomeRecords = item;
              }
            }
          });
          this.sales = result.sales;
          this.plantCurrent = result.plantCurrent;
          this.plantHistory = result.plantHistory;
          this.incomeRecords = newIncomeRecords;
          this.gardenInfo = gardenInfo;
          this.gardenPhoto = gardenInfo.photo && JSON.parse(gardenInfo.photo);
          if (result.video) {
            this.video = true;
            if (result.video.videoUrl) {
              this.setVideo(result.video.videoUrl);
            } else {
              this.getPlayAddr(result.video.videoCode);
            }
          }
          this.serviceList = result.serviceList.map((item) => {
            return {
              ...item,
              url:
                item.servicePhoto && JSON.parse(item.servicePhoto)[0]?.fileUrl,
            };
          });
        });
    },
    changeVideo(index) {
      if (this.current === index) return false;
      this.current = index;
      if (this.gardenInfo.video[index].videoUrl) {
        this.setVideo(this.gardenInfo.video[index].videoUrl);
      } else {
        this.getPlayAddr(this.gardenInfo.video[index].videoCode, (videoUrl) => {
          this.gardenInfo.video[index].videoUrl = videoUrl;
        });
      }
    },
    setVideo(url) {
      this.$nextTick(() => {
        let videoRefsmall = this.$refs.videoRefsmall;
        if (Hls.isSupported()) {
          this.hls = new Hls();
          this.hls.loadSource(url);
          this.hls.attachMedia(videoRefsmall);
          // this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
          //   videoRefsmall.play();
          // });
        } else if (videoRefsmall.canPlayType("application/vnd.apple.mpegurl")) {
          videoRefsmall.src = url;
          // videoRefsmall.addEventListener('canplay', function () {
          //   videoRefsmall.play()
          // })
        }
      });
    },
    openIncome(item) {
      this.open = true;
      this.name = item.householdName;
      this.$api.bigdata
        .groupByIncomeType({
          householdId: item.id,
        })
        .then((res) => {
          let total = 0;
          res.data.forEach((item) => {
            total += Number(item.incomeAmount);
          });
          this.total = total;
          this.tableData = res.data.map((item) => {
            return {
              ...item,
              width: Math.round((item.incomeAmount / total) * 100) + "%",
            };
          });
        });
    },
    getPlayAddr(videoCode, cb) {
      this.$api.bigdata
        .getPlayAddr({
          videoCode,
        })
        .then((res) => {
          this.setVideo(res.data);
          cb && cb(res.data);
        });
    },
  },
  mounted() {
    this.getResult();
  },
  beforeDestroy() {
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
      this.hls = null;
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    font-size: 18px;
    .left {
      width: 440px;
      .year-total {
        height: 120px;
        background: url(../assets/images/year_town.png);
        background-size: 100% 100%;
        margin-bottom: 30px;
        .list {
          display: flex;
          .round-rotate {
            flex: 1;
            margin: 10px 0;
          }
        }
      }
      .div-plantnow {
        ::v-deep .el-carousel__container {
          height: 80px;
          margin-top: 20px;
        }
        .div-plantnow-item {
          height: 80px;
          padding: 6px 10px 0 10px;
          line-height: 34px;
          display: flex;
          flex-direction: column;
          background-size: 100% 100%;
          &.lightLevel0 {
            background-image: url(../assets/images/green_bg_1.png);
          }
          &.lightLevel1 {
            background-image: url(../assets/images/yellow_bg_1.png);
          }
          &.lightLevel2 {
            background-image: url(../assets/images/red_bg_1.png);
          }
          .text-line {
            display: flex;
            justify-content: space-between;
            .text-cur {
              display: flex;
              .label {
                color: #eef8ff;
              }
              .text1 {
                color: #fcc401;
              }
              .text2 {
                color: #fcc401;
              }
            }
          }
        }
      }
      .div-planthis {
        margin-bottom: 30px;
        .title3 {
          margin-top: 14px;
          display: flex;
          align-items: center;
          color: #eef8ff;
          letter-spacing: 1px;
          .title-img {
            width: 48px;
            height: 48px;
          }
        }
        .div-planthis-list {
          height: 276px;
          overflow: auto;
        }
        .div-planthis-item {
          height: 80px;
          padding: 6px 10px 0 10px;
          margin-top: 12px;
          line-height: 34px;
          display: flex;
          flex-direction: column;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/b.png);
          background-size: 100% 100%;
          .text-line {
            display: flex;
            justify-content: space-between;
            .text-cur {
              display: flex;
              .label {
                color: #eef8ff;
              }
              .text1 {
                color: #eef8ff;
              }
              .text2 {
                color: #eef8ff;
              }
            }
          }
          .cycle {
            color: #eef8ff;
            letter-spacing: 1px;
            display: flex;
            .cycle-vari {
              color: #00a0fa;
            }
          }
        }
      }
      .income-list {
        margin: 10px 0 0;
        &.income-list-body {
          margin: 0;
          height: 278px;
          overflow: hidden;
          li {
            &:nth-child(odd) {
              div {
                background: rgba(0, 63, 152, 0.3);
              }
            }
            div {
              cursor: pointer;
            }
          }
        }
        li {
          display: flex;
          justify-content: space-between;
          div {
            font-size: 0;
            width: 49%;
            border-bottom: 1px solid rgba(0, 149, 255, 0.5);
          }
          span {
            display: inline-block;
            width: 50%;
            font-size: 18px;
            text-align: center;
            line-height: 46px;
          }
        }
      }
    }
    .middle {
      flex: 1;
      margin: 0 35px;
      .div-static {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/header.png)
          no-repeat;
        background-size: 100% 100%;
        height: 200px;
        .top {
          width: 100%;
          text-align: center;
          font-size: 36px;
          letter-spacing: 3px;
          font-weight: 700;
          background-image: linear-gradient(720deg, white, #face42);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          text-shadow: 0 0 8px #face42;
        }
        .middle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: #eef8ff;
          letter-spacing: 1px;
          .text {
            margin-left: 10px;
          }
        }
        .bottom {
          width: 100%;
          height: 113px;
          display: flex;
          justify-content: space-around;
          .bottom-item {
            width: 280px;
            height: 96px;
            background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/%E6%A4%AD%E5%9C%86%E5%BD%A2%20%2B%20%E6%A4%AD%E5%9C%86%E5%BD%A2%20%E8%92%99%E7%89%88.png)
              no-repeat;
            background-size: 100%;
            margin-top: 10px;
            padding: 14px 30px 8px 30px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            .label {
              opacity: 0.7;
              color: #eef8ff;
            }
            .value {
              font-size: 36px;
              color: #eef8ff;
            }
          }
        }
      }
      .div-pic {
        margin-top: 30px;
        .el-carousel {
          height: 802px;
        }
      }
    }
    .right {
      width: 440px;
      .video {
        display: flex;
        flex-direction: column;
        margin: 30px 0;
        height: 248px;
        .current {
          flex: 1;
          background: black;
          video {
            height: 100%;
            width: 100%;
          }
        }
        .sider {
          padding: 5px 0 0;
          .item {
            color: #eef8ff;
            font-size: 18px;
            line-height: 18px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            .round {
              display: inline-block;
              vertical-align: top;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 4px solid #eef8ff;
              margin-right: 8px;
            }
            &.active {
              color: #fec700;
              .round {
                border-color: #fec700;
              }
            }
          }
        }
      }
      .div-service {
        margin: 30px 0 0;
        height: 610px;
        overflow: auto;
        &.add-height {
          height: 962px;
        }
        .div-service-item {
          display: flex;
          & + .div-service-item {
            margin-top: 15px;
          }
          .el-image {
            width: 140px;
            height: 104px;
          }
          .item-right {
            line-height: 1.2;
            min-width: 0;
            flex: 1;
            margin-left: 10px;
            background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/gongying.png)
              no-repeat;
            background-size: 100% 100%;
            padding: 25px 20px 8px 16px;
            .datetime {
              opacity: 0.7;
              font-size: 16px;
              color: #eef8ff;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
